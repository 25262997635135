<template lang='pug'>

ion-list(
  v-if='collection.length !== 0'
)
  ion-searchbar(
    @ionInput="handleInput($event)"
    :placeholder='capitalize($t("ui.actions.search"))'
    inputmode='text'
    minlength='3'
    maxlength='10'
    show-clear-button='always'
    :clear-icon='closeCircleOutline'
    debounce='200'
    color='light'
  )

</template>

<script setup>

import { capitalize } from 'lodash'

import {
  closeCircleOutline,
} from 'ionicons/icons'

const query = defineModel({
  type: String,
  default: '',
})

const props = defineProps({
  collection: {
    type: Array,
    default: [],
  },
})

const { collection } = toRefs(props)

const handleInput = (event) => {
  query.value = event.target.value
} 

addIcons({
  'close-circle-outline': closeCircleOutline,
})

</script>
